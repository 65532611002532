import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";


import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';


export default function Relink() {
  return (
    <Layout pageTitle="Relink">

     <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Relink Belfast - USI Projects</title>
        <meta name="description" content="Urban Scale Interventions have worked with the charitable organisation who owns Portview to bring the whole site into use, through establishing an Education, Tourism, Resilience, Heritage and Employment masterplan."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

        <section class="text-gray-700 body-font heading-container-relink">
         <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Relink</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                Public Health Agency</p>
              </div>
          </section>
        </div>
      </section>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 class="text-6xl font-heading text-primaryyellow">Overview</h3>
                <p
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">Relink is a
                  climate friendly vision for transforming the Westlink and M2 bridges to help relink Belfast. The
                  project has been developed since 2019 through an extensive community and statutory stakeholder
                  engagement process in association with the Public Health Agency.<br/><br/>

                  The aim of Relink is to improve the health and social well-being of everyone using the Westlink and M2
                  bridges, through rejuvenation and animation of its footbridges. The bridges are being reconceived as
                  shared positive spaces that focus on connectivity and climate resilience with the primary focus of
                  improving wellbeing in the area.</p>



              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Mental Health<br/>
                  Climate<br/>Resilience<br/>Connectivity<br/>Tourism<br/>Technology</p>
                <p class="text-2xl font-bold uppercase mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">Policy Scoping<br/>
                  Market Analysis<br/>Stakeholder Consultation<br/>Public Engagement<br/>Strategic Vision<br/>Design
                  Strategy<br/>Project Management</p>

                <p class="text-2xl font-bold uppercase mt-8">Visit</p>
                <a href="http://relinkbelfast.com">
                  <p class="my-4 text-gray-500 leading-relaxed mt-0">
                    www.relinkbelfast.com
                  </p>
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>



      <div class="md:p-16 md:m-16 py-6">
        <video controls src="https://player.vimeo.com/external/491799610.hd.mp4?s=a879469becbac3f23fbb177bcb3590c1ee3e1baa&profile_id=175">
        </video>
      </div>





      <img class="w-full" src="../img/projects/relink-timeline.svg" alt=""/>

      <div className="w-6/6 m-auto py-6">
       <ReactCompareSlider
        itemOne={<ReactCompareSliderImage src="../img/projects/before.jpg" srcSet="../img/projects/before.jpg" alt="Concept" className=''/>}
        itemTwo={<ReactCompareSliderImage src="../img/projects/after.jpg" srcSet="../img/projects/after.jpg" alt="Delivery" />}
       />
      </div>

      <div class="text-center pt-28 pb-28">
        <h3 class="mt-2 text-3xl leading-8 font-bold  text-primaryyellow sm:text-5xl sm:leading-10 font-heading">
          The Relink Vision 2035
        </h3>
        <p class="mt-4 max-w-5xl text-xl md:text-2xl lg:text-3xl  mx-auto pt-6 leading-relaxed">
          Transformation of the Westlink into a blue and green linear park and greenway for the city by decking over the
          road and creating a place for play, biodiversity, attracting visitors and connecting the city centre back into
          the neighbourhoods. A shared space developed, owned and nurtured by local communities that supports good
          health and wellbeing.
        </p>
      </div>

      <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/view_greenway_section_cropped_a_1_oN05h1a7N.png"
        alt=""/>



      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <div class="flex flex-wrap ">
            <div class="w-full md:px-40 xl:w-2/4 py-10 xl:px-32 mb-4 md:mb-0">
              <h2 data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                class="text-3xl sm:text-5xl leading-8 font-bold  text-primaryyellow font-heading p-6 md:pl-0 md:pr-0 md:pb-0 md:mb-6 md:mr-20">
                Pilot Projects</h2>
              <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                class="text-xl md:text-2xl  p-6 md:pl-0 md:pr-0 md:pb-0 md:mb-20 md:mr-20">
                Four pilot projects for Clifton Street, Peter’s Hill, Divis Street and Grosvenor Road bridges to
                create more people-centred and healthier places that connect north and west Belfast to the city
                centre. Each bridge has its own unique personality and identity codesigned by local communities around
                themes of water, light, growth and pollution. </p>
            </div>
            <div data-scroll data-scroll-speed="1" class="w-full xl:w-2/4 py-4 sm:px-8 md:px-24 mb-4 md:mb-0">
              <img src="https://ik.imagekit.io/usi/project-pages/view_peters_iso_a_1_tWMIgdCOp.png" alt="foyle app"/>

            </div>
          </div>

        </div>
      </section>


      <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/divis-side-on-close_1_Hkgs5jTvcJ9G.png" alt=""/>


      <img data-scroll data-scroll-offset="200" data-scroll-speed="2" data-scroll-class="scroll-class"
        class="w-full md:pl-20 md:pr-20 pt-20 pb-20"
        src="https://ik.imagekit.io/usi/project-pages/clifton-side-on_a_1_AWe9712cs.png" alt=""/>


      <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/grosvenor-night-front-on-a_1_HHa7boN8X.png"
        alt=""></img>

<div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-blue-900">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../disappearingwall" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
